<template>
  <a @click="openLink(link)"
     class="tile-item" v-if=" link.type!='item item-divider'"
     style="position:relative;" :style="getBackground(link)">
    <i class="dashboard-item-icon" :class="link.icon_style" style="color:#7A6B59"></i>
    <span class="text dashboard-item-text" v-html="link.title[currentLanguage]" style="color:#7A6B59 !important"></span>
    <span v-if="link.target == '#/app/posts' && unreadNews > 0"
          class="position-absolute translate-middle badge rounded-pill bg-primary"
          style="top:15px; right:0px">
                {{ unreadNews }}
              </span>
  </a>
</template>
<script>
import {
  toastController
} from "@ionic/vue";
import {mapState, mapActions, mapGetters} from "vuex";
import {isPlatform} from '@ionic/vue';
import router from "@/router";

const config = require('@/config')

export default {
  components: {},
  props: ['link'],
  data() {
    return {
      isDesktop: null,
      menulinks: [],
      title: config.full_name,
      confDate: config.conf_date,
      currentTime: '',
      polls: [],
      questions: [],
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapGetters('auth', ['user']),
    ...mapState('wordings', ['wordings', 'currentLanguage']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion', 'primaryColor']),


  },
  methods: {
    ...mapActions('menulinks', ['getAllLinks', 'getParents', 'isExternalLink']),
    ...mapActions('sync', ['start']),
    ...mapActions('auth', ['GET_USERDATA']),
    ...mapActions("personalDB", ['getItem', 'setTime', 'getUnreadNews']),
    ...mapActions('googleanalytics', ['trackWithLabel']),

    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',

          })
      return toast.present();
    },


    getBackground(link) {
      let styleToReturn = {
        'background': '#white'
      }
      if (link.bg_color) {
        styleToReturn = {
          'background': link.bg_color
        }
      }
      if (link.background_image) {
        styleToReturn = {
          'background-image': 'url("https://fileserver.documedias.systems/' + link.background_image + '")',
          'background-repeat': 'no-repeat',
          'background-position': 'center center',
          'background-size': 'contain'
        }
      }
      return styleToReturn
    },

    async openLink(link) {
      this.trackWithLabel('menu_link:' + link.title.en)
      let external = await this.isExternalLink(link.redirect)
      if (external) {
        window.open(link.redirect, '_blank').focus();
      } else {
        router.push(link.redirect);
      }
    },

    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },

  },
  async created() {
    this.isDesktop = isPlatform('desktop');


  },
  watch: {
    '$route': {
      immediate: true,
      async handler() {
        let time = await this.getItem('timeStamps_' + config.conference);
        await this.setTime({name: 'timeStamps_' + config.conference, item: time});
        await this.getUnreadNews('posts');
      }
    }
  },
};
</script>
<style lang="scss" scoped>

.dashboard-item-text{
  max-width: 100%;
}
.tile-item {
  display: flex;
  width: 220px;
  height: 160px;
  max-height: 160px;
  border-radius: 15px;

  line-height: 0.9rem;
  text-decoration: none;
  box-shadow: 0px 5px 8px rgb(0 0 0 / 10%);
  justify-content: center;


  .icon {
    display: block;
    font-size: 2.5rem;
    width: 60px;
    padding-top: 15px;
  }
  .dashboard-item-icon{
    font-size: 3.3rem;
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: unset;
    transform: unset;
  }

  .text {
    position: absolute;
    top: 5px;
    left: 10px;
    font-size: 1.2rem;
    line-height: 1.3rem;
    overflow-y: visible;
    color: #000;
    font-weight: bold;
    padding: 5px 10px;
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .icon-sponsor, .icon-aussteller {
    font-size: 3.1rem !important;
    font-family: "documedias-icons";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 50px;
    padding-bottom: 5px;
  }

  .icon-sponsor:before {
    content: "\e904";
  }

  .icon-aussteller:before {
    content: "\e903";
  }

  .icon-aussteller-new {
    width: 70px;
    background-size: 35px 35px;
  }
}

@media(max-width: 372px) {
  .tile-item {
   width: 130px;
    height: 120px;
    max-height: 120px;
    text-align: center;

    .text{
      font-size: 0.9rem !important;
      position: relative;
      top: unset !important;
      left: unset !important;
    }
    .icon-sponsor, .icon-aussteller {
      font-size: 2.4rem !important;
      width: 70px;
    }
  }

  .dashboard-item-icon{
    font-size: 2.6rem !important;
    position: absolute;
    bottom: 10px;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }


}

@media(min-width: 372px) {
  .tile-item {
   width: 160px;
    height: 120px;
    max-height: 120px;
    text-align: center;

    .text{
      font-size: 0.9rem !important;
      position: relative;
      top: unset !important;
      left: unset !important;
    }
    .icon-sponsor, .icon-aussteller {
      font-size: 2.4rem !important;
      width: 70px;
    }
  }

  .dashboard-item-icon{
    font-size: 2.6rem !important;
    position: absolute;
    bottom: 10px;
    left: 50% !important;
    transform: translateX(-50%) !important;
  }


}

</style>
