<template>
  <ion-page>
    <div id="dashboard-sub">
      <base-layout>
        <ion-content class="has-header has-bottom-banner" style="--background: unset" :scroll-x="false" :scroll-y="false" >
          <div class="dashboard-content"
               style=" overflow:auto;padding-bottom:70px; height:100%; margin-top:0px;" :style="{'background': primaryColor}">
            <h2 style="text-align: center;color:#fff" class="ion-padding" v-html="parentLink.title['en']" v-if="parentLink.title"></h2>
            <div class="container-xl mt-3">
              <div class="row  g-1 justify-content-center">
                <template v-for="link in menulinks" :key="link.id" >
                  <div v-if="link.type!='item item-divider'" class="col p-2 tile-wrapper">
                    <dashboard-item :link="link" v-if="link.type!='item item-divider'"> </dashboard-item>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </ion-content>
      </base-layout>
    </div>
  </ion-page>
</template>
<script>
import {
  toastController
} from "@ionic/vue";
import {mapState, mapActions} from "vuex";
import {addIcons} from "ionicons";
import {syncOutline} from "ionicons/icons";
import DashboardItem from './DashboardItem.vue';

addIcons({"sync-outline": syncOutline});
const config = require('@/config')

export default {
  name: "DashboardSub",
  components: {
    DashboardItem,
  },
  data() {
    return {
      parentLink: {},
      menulinks: [],
      title: config.full_name,
      confDate: config.conf_date,
      currentTime: '',
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('currentConference', ['currentConference', 'fullName', 'shortName', 'apiVersion','primaryColor']),

  },
  methods: {
    ...mapActions('menulinks', ['getAllLinks', 'getChildItems', 'getLinkById']),
    ...mapActions('sync', ['start']),
        ...mapActions('googleanalytics', ['trackWithLabel']),

    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',
          })
      return toast.present();
    },
    login() {

    },
    goToProfile() {

    },
    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },
    async prepareLinks(id) {
      this.parentLink = (await this.getLinkById(id));
      this.menulinks = (await this.getChildItems(id)).sort((a, b) => a.order - b.order);
    }
  },
  async created() {
    let id = this.$route.params.id;
    this.prepareLinks(id);

  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        let id = this.$route.params.id;
        this.prepareLinks(id)
      }
    },
    '$route': {
      immediate: true,
      async handler(route) {
        if (route.name == 'DashboardSub') {
          let id = route.params.id;
          this.prepareLinks(id)
        }
      }
    }
  }
};
</script>
<style lang="scss">


</style>
